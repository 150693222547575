// Courier
export const ALTO_PHARMACY_ID = 'phr_01G9CM93X1NFP1C9H9K50DPKHX';
// Mail Order
export const CUREXA_PHARMACY_ID = 'phr_01GCA54GVKA06C905DETQ9SY98';
export const CAREPOINT_PHARMACY_ID = 'phr_01GA9HPVBVJ0E65P819FD881N0';
export const AMAZON_PHARMACY_ID = 'phr_01GA9HPV5XYTC1NNX213VRRBZ3';
export const GOGOMEDS_PHARMACY_ID = 'phr_01HQVGZ1PTGVCD9EVKZVB18WP9';
export const REDBOX_PHARMACY_ID = 'phr_01HQQ1DHTW6ZQ19W5R3G22WQ41';
export const TAILORMADE_PHARMACY_ID = 'phr_01HNR6THE9BE7XBFQMZN7ZEFGA';
export const EMPOWER_PHARMACY_ID = 'phr_01HQD5F3C5M88JVNZWVVK9DTAE';
export const HONEYBEE_PHARMACY_ID = 'phr_01GA9HPXNE3TGEWPK91YY8Z4TS';
export const TRUEPILL_PHARMACY_ID = 'phr_01HT0PDJN87W3QA7YD2ZT5Q0DD';
export const COSTCO_PHARMACY_ID = 'phr_01GA9HPWTQ75YNJGFD505X5C4J';
export const INNOVASCRIPT_PHARMACY_ID = 'phr_01HSC9J1KM4EYZ7ZZ74YT1EKY2';
export const AMBROSIA_PHARMACY_ID = 'phr_01GA9HPX3N3Y0RDQV1RMP9F14J';
export const WOMENS_INTERNATIONAL_PHARMACY_ID = 'phr_01J0XD00F5NT6DBT7M97SX4FSS';
export const REDROCK_SPRINGVILLE_PHARMACY_ID = 'phr_01GA9HPXHHRTQS0ZQ36XHND9AW';
export const REDROCK_STGEORGE_PHARMACY_ID = 'phr_01GA9HPXM8SCGXVWCM4Q853B2X';
export const FOOTHILLS_PHARMACY_ID = 'phr_01J3GGVK67FDQSZJ7RX3WXFSCP';
export const SMARTSCRIPTS_PHARMACY_ID = 'phr_01GA9HPVM879VMWJF53XH9M8CZ';
export const OLYMPIA_PHARMACY_ID = 'phr_01J7E55CF7XY23BPJ97YSBQY23';
export const COST_PLUS_PHARMACY_ID = 'phr_01HH0B05XNYH876AY8JZ7BD256';
export const WALMART_MAIL_ORDER_PHARMACY_ID = 'phr_01GA9HPXGSDTSB0Z70BRK5XEP0';
export const HEALTHWAREHOUSE_PHARMACY_ID = 'phr_01J0SQ2R33YH0YZ3PZ9NHSGMAV';
export const STRIVE_PHARMACY_ID = 'phr_01JD85KBRQA4CNCAY257HH8ERH';
export const OPENLOOP_PHARMACY_ID = 'phr_01GA9HPTYS0T27TZVTTC0FNBAX';
export const GIFTHEALTH_PHARMACY_ID = 'phr_01J6APWHGNFJCE74SB031VYPHW';
export const EPIQ_PHARMACY_ID = 'phr_01J526CX3EGRYEJATSXG5R2004';

// Brick & Mortal as Mail Order:
export const DANIA_PHARMACY_ID = 'phr_01JQPTRYECNFYAMBT9KJ6S4AYV';

// Test mail order in neutron
export const PHOTON_TEST_PHARMACY_ID = 'phr_01JQC7SHYSRQRG9ENTN01TMY99';
